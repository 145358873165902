import './App.scss';
import 'react-image-lightbox/style.css';

import LandingPage from './components/pages/LandingPage';
import gallery_data from './assets/json/gallery.json';
import story_data from './assets/json/story_photos.json';

import './assets/stylesheets/flag-icon.scss'

// import PageUnderwayWorks from './components/pages/PageUnderwayWorks';

function App() {
  return (
    <div className="App">
      {/* <PageUnderwayWorks /> */}
      <LandingPage gallery_data={gallery_data} story_data={story_data} />
    </div>
  );
}

export default App;
