import React from 'react'
import './TextBlock.scss';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export default function TextBlock({data, ...props}) {
  const { default_title, default_desc } = data
  return (
    <div className="text-block">
      <Container>
        <div className="text-block__container">
          <div className="text-block__header">
            <h2>
              <FormattedMessage
                id = "headblock.title"
                defaultMessage={default_title}
              />
            </h2>
          </div>
        </div>
        <p>
          <FormattedMessage
            id = "headblock.text1"
            defaultMessage={default_desc[0]}
          />
        </p>
        <p className="text-block__see-all">
          <FormattedMessage
            id = "headblock.text2"
            defaultMessage={default_desc[1]}
          />
        </p>
        <p>
          <FormattedMessage
            id = "headblock.text3"
            defaultMessage={default_desc[2]}
          />
        </p>
      </Container>
    </div>
  )
}
