import React, { useState } from 'react'
import { IntlProvider } from 'react-intl'
import Russian from '../../lang/ru.json'
import English from '../../lang/en.json'
import Spain from '../../lang/es.json'
import French from '../../lang/fr.json'
import Chinese from '../../lang/zh.json'

export const Context = React.createContext()

// const local = navigator.language.substring(0, 2).toLowerCase()
const local = 'en'

let lang 
if (local === 'en') {
    lang = English
} else if (local === 'es') {
    lang = Spain
} else if (local === 'fr') {
    lang = French
} else if (local === 'zh') {
    lang = Chinese
} else {
    lang = Russian
}

const LanguageWrapper = (props) => {
    const [locale, setLocale] = useState(local)
    const [messages, setMessages] = useState(lang)

    function selectLanguage(e) {
        const newLocale = e.target.value
        console.log("newLocale = ", newLocale)

        setLocale(newLocale)
        if (newLocale === 'en') {
            setMessages(English)
        } else if (newLocale === 'es') {
            setMessages(Spain)
        } else if (newLocale === 'fr') {
            setMessages(French)
        } else if (newLocale === 'zh') {
            setMessages(Chinese)
        } else {
            setMessages(Russian)
        }
    }

    return (
        <Context.Provider value={{ locale, messages, selectLanguage }}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    )
}

export default LanguageWrapper