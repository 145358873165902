import React, { useContext, useState } from 'react'
import './FamilyList.scss';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import { FormattedMessage } from 'react-intl';
import { Context } from './LanguageWrapper';
import img_title from '../../assets/images/family_title.svg'

const FamilyItem = (props) => {

    const {item} = props

    const langContext = useContext(Context);

    const images = item.phlang ? item[`${langContext.locale}_photos`] : item.photos

    const [photoIndex, setPhotoIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const openModalAndSetIndex = (index) => {
        setPhotoIndex(index);
        setShowModal(true);
        return;
    };

    return (
        <div className={`family-item ${item.isdark ? 'family-item__dark-bg' : ''}`}>
            <Container>
                {item.type === "l" ? (
                    <Row>
                        <Col lg={7}>
                            <p lang={langContext.locale}>
                                <FormattedMessage
                                    id={item.desc_key}
                                    defaultMessage={item.desc}
                                />
                            </p>
                        </Col>
                        <Col className={`family-item__photos ${images.length === 1 ? 'family-item__photos__one' : ''}`}>
                            {
                                images?.map((item, index) => {
                                    return <Image
                                        key={index}
                                        className="family-item__photo_main"
                                        src={item}
                                        alt=''
                                        // onClick={() => openModalAndSetIndex(0)}
                                        fluid
                                    />

                                })
                            }
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col className={`family-item__photos family-item__photos__start ${images.length === 1 ? 'family-item__photos__one' : ''}`}>
                            {
                                images?.map((item, index) => {
                                    return <Image
                                        key={index}
                                        className="family-item__photo_main"
                                        src={item}
                                        alt=''
                                        // onClick={() => openModalAndSetIndex(0)}
                                        fluid
                                    />

                                })
                            }
                        </Col>
                        <Col lg={7}>
                            <p lang={langContext.locale}>
                                <FormattedMessage
                                    id={item.desc_key}
                                    defaultMessage={item.desc}
                                />
                            </p>
                        </Col>
                    </Row>
                )}
            </Container>

            {showModal && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onImageLoad={() => {
                        window.dispatchEvent(new Event('resize'));
                    }}
                    onCloseRequest={() => setShowModal(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                            (images + images.length - 1) % images.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex(
                            (photoIndex + images.length + 1) % images.length
                        )
                    }
                />
            )}
        </div>
    )
}

export default function FamilyList(props) {
    const { items } = props

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div className="family-list">
            <div className='family-list-pattern'></div>
            <div className='family-list__items-container'>
                {items.map((item, index) => {
                    item.title_key = `felement${index + 1}.title`
                    item.desc_key = `felement${index + 1}.desc`
                    item.isdark = index % 2 === 0
                    return <FamilyItem item={item} key={index} />
                })}
                <div className='banner-family__text-bg' onClick={scrollToTop}>
                    <div className='banner-button'></div>
                    <Image src={img_title} className='banner-family__title'></Image>
                </div>
            </div>
        </div>
    )
}
