import React, { useContext, useState } from 'react'
import Banner from '../containers/Banner'
import Footer from '../containers/Footer'
import Header from '../containers/Header'
import Gallery from '../containers/Gallery'
import TextBlock from '../containers/TextBlock'
import HappyBirthday from '../containers/HappyBirthday'
import { Container } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import FamilyList from '../containers/FamilyList'
import { Context } from '../containers/LanguageWrapper'
import BannerFamily from '../containers/BannerFamily'
import './LandingPage.scss'

export default function LandingPage({
  gallery_data, story_data
}) {
  const pageInfo = {
    default_text: {
      default_title: "Интердом: 90 лет семейной фотохроники во всех красках",
      default_desc: [
        "Интердом не случайно называют «барометром планеты»: со дня основания школа принимает ребят, пострадавших от военных столкновений и тех\u00ADно\u00ADген\u00ADных катастроф. Интердом стал настоящим домом для тысяч благодарных воспитанников из более чем 80 стран мира. Жизнь интернациональной школы запечатлена в фотографиях, которые бережно хранят почти вековую историю. Каждый кадр большого семейного фото\u00ADальбома наполнен теплом, ра\u00ADдостью и передает особую атмосферу Интердома.",
        "Специально к 90‑летию школы архивные черно‑белые снимки оцифрованы и пере\u00ADведены в полноцветный формат — теперь они доступны в электронной версии фото\u00ADвыс\u00ADтавки. Благодаря современным тех\u00ADно\u00ADло\u00ADги\u00ADям хронику Интердома можно увидеть во всех красках.",
        "Наша работа с фотоархивом продолжается, ведь у Интердома уникальная история! Коллекция электрон\u00ADной фотовыставки пополняется и всегда доступна для всех желающих.",
      ]
    },
    photo_items: gallery_data,
    story_items: story_data
  }

  const queryParams = new URLSearchParams(window.location.search)
  const [currentTab, setCurrentTab] = useState(queryParams.get("tab"))

  const onTabChange = (eventKey) => {
    setCurrentTab(eventKey)
  }

  const langContext = useContext(Context);

  return (
    <div className="page landing-page">
      <Header
        defaultTab={currentTab === 'photo' ? 'photo' : 'family'}
        onTabChange={onTabChange}
      />
      {currentTab === 'photo' ? (
        <>
          <Banner />
          <TextBlock data={pageInfo.default_text} />
          <Gallery items={pageInfo.photo_items} />
          <HappyBirthday />
          <Footer withsocial={true}/>
        </>
      ) : (
        <>
          <BannerFamily />
          <div className="text-block text-block-family">
            <div className='left-bg-img'></div>
            <div className='right-bg-img'></div>
            <Container>
              <p lang={langContext.locale}>
                <FormattedMessage
                  id="familyblock.desc"
                  defaultMessage={``}
                />
              </p>
            </Container>
          </div>
          <FamilyList items={pageInfo.story_items} />
          <Footer withsocial={false}/>
        </>
      )}
    </div>
  )
}
