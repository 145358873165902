import React, { useContext } from 'react';
import './Header.scss';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { Context } from "../containers/LanguageWrapper";

export default function Header(props) {

  const { defaultTab, onTabChange } = props

  const context = useContext(Context);

  const onTabSelect = (eventKey) => {
    onTabChange(eventKey)
  }

  function flagIcon(locale) {
    switch (locale) {
      case 'ru':
        return 'flag-icon-rus'
      case 'en':
        return 'flag-icon-gbr'
      case 'es':
        return 'flag-icon-esp'
      case 'fr':
        return 'flag-icon-fra'
      case 'zh':
        return 'flag-icon-chn'
      default:
        return 'flag-icon-rus';
    }
  }

  return (
    <div>
      <Container>
        <div className="header">
          <div className='tabs'>
            <Tabs
              onSelect={onTabSelect}
              defaultActiveKey={defaultTab}
            >
              <Tab eventKey={"family"} title={context.messages['tab.family']}></Tab>
              <Tab eventKey={"photo"} title={context.messages['tab.photo']}></Tab>
            </Tabs>
          </div>
          <div className='lang-panel'>
            <div className={`${flagIcon(context.locale)} flag-icon`} />
            <select id='lang' className="header__select" value={context.locale} onChange={context.selectLanguage}>
              <option value='ru'>Русский</option>
              <option value='en'>English</option>
              <option value='es'>Español</option>
              <option value='fr'>Français</option>
              <option value='zh'>中文</option>
            </select>
          </div>
        </div>

      </Container>
    </div>
  )
}
