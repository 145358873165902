import React from 'react'
import './Banner.scss';

export default function Banner() {
  return (
    <div className="banner">
      <div className="banner__container">
        <div className="banner__img header_logo_img"></div>
        <a href="https://edu.gov.ru/" className="banner__logo logo_minpros"> </a>
      </div>
    </div>
  )
}
