import React, { useState } from 'react'
import './Gallery.scss';
import { Container, Row } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import { FormattedMessage } from 'react-intl';

const GalleryItem = ({
  title,
  desc,
  photo,
  title_key,
  desc_key
}) => {

  const images = [photo]

  const [photoIndex, setPhotoIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const openModalAndSetIndex = (index) => {
    setPhotoIndex(index);
    setShowModal(true);
    return;
  };

  return (
    <div className={`gallery-item `}>
      <Row lg={12} className="gallery-item__photo_container">
        <img className="gallery-item__photo_main" src={photo} alt='' onClick={() => openModalAndSetIndex(0)} />
      </Row>
      <div className="headtitle">
        <FormattedMessage
          id={title_key}
          defaultMessage={title}
        />
      </div>

      <p>
        <FormattedMessage
          id={desc_key}
          defaultMessage={desc}
        />
      </p>

      {showModal && (
        <Lightbox
          mainSrc={images[photoIndex]}
          // nextSrc={images[(photoIndex + 1) % images.length]}
          // prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onImageLoad={() => {
            window.dispatchEvent(new Event('resize'));
          }}
          onCloseRequest={() => setShowModal(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (images + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex + images.length + 1) % images.length
            )
          }
        />
      )}
    </div>
  )
}

export default function Gallery(props) {
  const { items } = props

  return (
    <div className="gallery">
      <Container>
        <div className="gallery__items-container">
          {items.map((item, index) => {
            item.title_key = `element${index + 1}.title`
            item.desc_key = `element${index + 1}.desc`
            if (index !== 22) {   // 23-я фотография была "Папа из Камеруна". Её запретили выставлять
              return <GalleryItem {...item} key={index} />
            } else {
              return null
            }
          })}
        </div>
      </Container>

    </div>
  )
}
