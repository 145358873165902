import React from 'react'
import './HappyBirthday.scss';
import { Container, Row } from 'react-bootstrap';

export default function HappyBirthday({data, ...props}) {
  return (
    <div className="happy-birthday">
      <Container className="happy-birthday__container">
        <Row>
          <a href="http://interdomivanovo.ru/" className="logo logo_interdom">&nbsp;</a>
          <div className="inscription happy_birthday"></div>
        </Row>
      </Container>
    </div>
  )
}
