import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './Footer.scss'
import { FormattedMessage } from 'react-intl'

export default function Footer(props) {

  const { withsocial } = props

  const social = [
    {
      href: " https://t.me/interdom_telegram",
      icon: 'icon-telegram'
    },
    {
      href: "https://vk.com/interdom_ivanovo",
      icon: 'icon-vk'
    },
  ]
  return (
    <footer className={`footer  `}>
      <div className="footer__top">
        <Container>
          <Row className="footer__top-row">
            {<Col lg={3} sm={6}>
              <div className="footer__contacts">
                <p>
                  <FormattedMessage
                    id="footer.offsite"
                    defaultMessage=" "
                  />
                  <a href="http://interdomivanovo.ru/">http://interdomivanovo.ru/</a>
                </p>
                <p>
                  <FormattedMessage
                    id="footer.phone"
                    defaultMessage=" "
                  />
                  8 (495) 911‑61‑11
                </p>
              </div>
            </Col>}
            <Col lg={3} sm={6} className="footer__social-container">
              {withsocial &&
                <div className="footer__social">
                  {social.map((item, index) => <a key={index} href={item.href} target="noopener noreferrer"><i className={item.icon}></i></a>)}
                </div>
              }
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="footer__bottom">
        <Col className="footer__copyright">
          &copy; «Центр Международного Сотрудничества Министерства Просвещения Российской Федерации», {new Date().getFullYear()} г..
        </Col>
      </Container>
    </footer>
  )
}
