import React from 'react'
import './BannerFamily.scss';
import { Image } from 'react-bootstrap';
import img from '../../assets/images/family_bg_big.png'
import img_title from '../../assets/images/family_title.svg'

export default function BannerFamily() {
  return (
    <div className="banner-family">
      <Image src={img} className="banner-family__img"></Image>
      <div className="logo-interdom-b banner-family__logo"></div>
      <div className='banner-family__text-bg'>
        <Image src={img_title} className='banner-family__title'></Image>
      </div>
    </div>
  )
}
